<script>
export default {
  name: "tasklist",
  data() {
    return {
      tasklist: []
    }
  },
  mounted() {
    this.gettasklist()
  },
  methods: {
    gettask(id) {
      this.$sa0.post({
        url: this.$api('二级：领取采集任务'),
        data: {
          id: id
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.gettasklist()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    contentTurn(content) {
      return content.split("\n").join("<br/>");
    },
    gettasklist() {
      this.$sa0.post({
        url: this.$api('二级：采集任务列表'),
        data: {},
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.tasklist = response.data.list
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
  }
}
</script>
<template>
  <div>
    <table class="layui-table" lay-size="sm">
      <thead>
      <tr>
        <th>任务内容</th>
        <th>任务状态</th>
        <th>操作</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item,key) in tasklist" :key="key">
        <td v-html="contentTurn(item.content)"></td>
        <td>
          <div v-if="Number(item.status) == 1">待处理</div>
          <div v-if="Number(item.status) == 2">已接收</div>
          <div v-if="Number(item.status) == 3">已完成</div>
        </td>
        <td>
          <a-button v-if="Number(item.status) == 1" @click="gettask(item.id)" type="primary">接收任务</a-button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<style scoped>

</style>